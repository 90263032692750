import React, { Component } from 'react'

import "./AddMenuContent.scss";
import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';

import RadioGroup from '../partials/RadioGroup';

class EditMenuContent extends Component {

    constructor(props) {
        super(props);
        
        const user = localStorage.getItem(process.env.REACT_APP_VERSION);

        this.state = {
            name: "",
            logo: false,
            restaurant: "",
            loading: true,
            visible: false,
            uploading: false,
            english: '',
            shortcut: '',
            id: props.data.id,
            errors: {},
            user: JSON.parse(user),
        }

        this.onComplete = props.onComplete;
        this.removeImage = this.removeImage.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.onFileChanged = this.onFileChanged.bind(this);
    }

    componentDidMount() {
        const id = this.state.id;
        const { services } = this.context;
        const { t } = this.props;

        services.menusApi.getById(id)
            .then(({ Menu }) => {
                const name = Menu.name;
                const logo = Menu.logo;
                const restaurant = Menu.restaurant;
                const visible = Menu.visible ? t('common.txt_yes', 'Sim') : t('common.txt_no', 'Não');
                const shortcut = Menu.show_shortcut ? t('common.txt_yes', 'Sim') : t('common.txt_no', 'Não');
                const english = Menu.show_english ? t('common.txt_yes', 'Sim') : t('common.txt_no', 'Não');
                const show_menu = Menu.show_menu ? t('common.txt_yes', 'Sim') : t('common.txt_no', 'Não'); //todo double check vars

                this.setState({ name, logo, restaurant, shortcut, english, visible, loading: false, public:show_menu });
            });
    }

    validate() {
        let errors = {};
        let { name, restaurant } = this.state;
        
       const isValidName = name.length == 0 ? null : true;
       errors.name = (isValidName ? null : "O nome do menu não é válido!");

       const isValidRestaurant = restaurant.length == 0 ? null : true;
       errors.restaurant = (isValidRestaurant ? null : "O nome do restaurante não é válido!");

        this.setState({ errors });

        return isValidName && isValidRestaurant;
    }

    handleFocus(event) {
        const { name } = event.target;
        const { errors } = this.state;
        errors[name] = "";
        this.setState({ errors });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value.replace('"', '') });
    }

    handleToggle = (event) => {
        this.setState({ [event.id]: event.selected });
    }

    onFileChanged = (event) => {
        let state = this.state;

        const view = this;
        const { files } = event.target;
        const file = files[0];
        const url = process.env.REACT_APP_WEBSITE_NAME + '/gateway/amazon?file-name=' + file.name + '&file-type=' + file.type;

        this.setState({ uploading: true });

        fetch(url, {
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', result.signedRequest, true);

            file.signedRequest = result.signedRequest;

            var formData = new FormData();
            formData.append("file", file);

            xhr.onload = function () {
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    view.setState({ uploading: false, logo: result.url });
                }
            };

            xhr.send(file);
        });
    }

    removeImage = (event) => {
        event.preventDefault();
        this.setState({ logo: "" });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validate())
            return;

        const { t } = this.props;
        this.setState({ loading: true });

        const { services } = this.context;
        const show_english = this.state.english === t('common.txt_yes', 'Sim');
        const show_shortcut = this.state.shortcut === t('common.txt_yes', 'Sim');
        const visible = this.state.visible === t('common.txt_yes', 'Sim');
        const { logo, name, restaurant, id } = this.state;

        services.menusApi.update({
            name,
            restaurant,
            logo,
            show_english,
            show_shortcut,
            visible,
            id
        }).then(({ updateMenu }) => {
            if (updateMenu && updateMenu.id) {
                this.onComplete();
            } else console.log("Error updating menu");
        }).catch(error => console.log(error));
    }

    render() {
        const { errors, uploading, logo, loading, user } = this.state;
        const { t } = this.props;

        return (
            <div className={loading ? "edit-menu loading" : "edit-menu"}>
                <h2>{t('admin_edit_menu.title', 'Editar menu')}</h2>
                <form autoComplete="off">
                    <input
                        type="text"
                        placeholder={t('placeholder.menu_name', "Nome do menu")}
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                    />
                    {errors.name ? <div className="error">{errors.name}</div> : null}
                    <input
                        type="text"
                        placeholder={t('placeholder.restaurant_name', "Nome do estabelecimento")}
                        name="restaurant"
                        value={this.state.restaurant}
                        onChange={this.handleChange}
                        className="m-top-4"
                    />
                    {errors.restaurant ? <div className="error">{errors.restaurant}</div> : null}
                    
                    <label htmlFor="logo" className={uploading ? "button button-upload-image upload m-top-4" : "button button-upload-image m-top-4"}>
                        <span>{logo ? "logo.png" : t('btn.upload_logo', 'Carregar logótipo')}</span>
                        <span>{logo ? t('btn.upload_replace', 'clique para substituir') : t('common.txt_optional', '(opcional)') }</span>
                        {logo ? <a href="#" className="remove" onClick={this.removeImage}><img src="/images/admin/icons/delete.png" /></a> : null}
                    </label>
                    <input type="file" id="logo" onChange={this.onFileChanged} accept="image/x-png,image/gif,image/jpeg" />
                </form>
                <div className="row m-top-4" style={!this.state.english ? {display: 'none'} : null }>
                    <div className="label">
                        {process.env.REACT_APP_VERSION === 'sb:user:en' ?
                            <h2>{t('placeholder.portuguese_version', 'Versão em *Português')}</h2>
                            :
                            <h2>{t('placeholder.english_version', 'Versão em Inglês')}</h2>
                        }
                    </div>
                    <RadioGroup id="english" name="number" options={[t('common.txt_yes', 'Sim'), t('common.txt_no', 'Não')]} selected={this.state.english} onChange={this.handleToggle}/>
                </div>
                <div className="row m-top-4">
                    <div className="label">
                        <h2>{t('placeholder.drinks_shortcut', 'Atalho para bebidas')}</h2>
                    </div>
                    <RadioGroup id="shortcut" name="number" options={[t('common.txt_yes', 'Sim'), t('common.txt_no', 'Não')]} selected={this.state.shortcut} onChange={this.handleToggle}/>
                </div>
                <div className="row m-top-4">
                    <div className="label">
                        <h2>{t('placeholder.public_menu', 'Menu visível')}</h2>
                    </div>
                    <RadioGroup id="visible" name="number" options={[t('common.txt_yes', 'Sim'), t('common.txt_no', 'Não')]} selected={this.state.visible} onChange={this.handleToggle}/>
                </div>
                <div className="m-top-8">
                    <div onClick={this.handleSubmit} className="btn-primary">{t('btn.save', 'Guardar')}</div>
                </div>
            </div >
        )
    }
}

EditMenuContent.contextType = ServicesContext;

export default withTranslation()(EditMenuContent);