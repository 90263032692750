import React, { Component } from 'react';

import { ServicesContext } from '../../context/services';
import { withTranslation } from 'react-i18next';
import './Menu.scss';

import Styles from "../utils/styles";

import MenuList from './MenuList';
import MenuItems from './MenuItems';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: '€',
      language: 'pt',
      opened: false,
      detail: false,
      data: null,
      item: null,
      tab: false,
      tabdetail: null,
      lang_pt: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onBackMainMenu = this.onBackMainMenu.bind(this);
    this.onSelectedClick = this.onSelectedClick.bind(this);
    this.onGetSubItems = this.onGetSubItems.bind(this);
    this.onLanguageClick = this.onLanguageClick.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const { services } = this.context;

    services.menusApi.getById(id)
      .then(({ Menu }) => {
        const currency = this.getCurrency(Menu);
        const lang_pt = this.getLang(Menu);
        this.setState({ data: { Menu }, currency, lang_pt });
        this.trackPageView(id, Menu.views);
      });
  }

  getLang(menu) {
    let lang_pt = this.state.lang_pt;
    if (menu.user && menu.user.country) {
      lang_pt = menu.user.country.lang_pt;
    }
    return lang_pt;
  }

  getCurrency(menu) {
    let currency = this.state.currency;
    let lang_pt = this.state.lang_pt;
    if (menu.user && menu.user.country) {
      currency = menu.user.country.currency;
      lang_pt = menu.user.country.lang_pt;
    }

    return currency;
  }

  trackPageView(id, views) {
    const { services } = this.context;
    const count = views ? views + 1 : 1;
    services.menusApi.updateViews(id, count);
  }

  onBackClick() {
    this.setState({ detail: false, item: null });
  }

  onBackMainMenu() {
    this.setState({ tab: false });
    this.componentDidMount();
  }

  onLanguageClick(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const lang = currentTarget.dataset.lang;
    this.setState({ opened: true, language: lang });
  }

  onSelectedClick(id) {
    const { services } = this.context;

    this.setState({ detail: true, item: null });

    services.itemListApi.getById(id)
      .then(({ ItemList }) => this.setState({ item: { ItemList } }));
  }

  checkLogo(menu, style) {
    if (menu.logo && menu.logo != "null") {
      return <img className="logo" src={menu.logo} alt={menu.restaurant} />;
    } else {
      return <h1 style={style.text}>{menu.restaurant}</h1>;
    }
  }

  renderLanguages(english, style) {
    const { t } = this.props;
    if (english && process.env.REACT_APP_VERSION === 'sb:user') {
      return (
        <nav className="lang">
          <a href="#" className="active" style={style ? style.buttons.background : {}} data-lang="pt" onClick={this.onLanguageClick}><span style={style.text}>PT</span></a>
          <a href="#" data-lang="en" style={style ? style.buttons.background : {}} onClick={this.onLanguageClick}><span style={!style.darkMode ? { color: "#5C5C5C" } : style.text}><div style={{ opacity: '0.8' }}>EN</div></span></a>
        </nav>
      );
    } else if (english && process.env.REACT_APP_VERSION === 'sb:user:en') {
      return (
        <nav className="lang">
          <a href="#" className="active" data-lang="en" style={style ? style.buttons.background : {}} onClick={this.onLanguageClick}><span style={style.text}>EN</span></a>
          <a href="#" style={style ? style.buttons.background : {}} data-lang="pt" onClick={this.onLanguageClick}><span style={!style.darkMode ? { color: "#5C5C5C" } : style.text}><div style={{ opacity: '0.8' }}>PT</div></span></a>
        </nav>
      );

    } else {
      return (
        <nav className="lang solo">
          <a href="#" className="active" style={style ? style.buttons.background : {}} data-lang={process.env.REACT_APP_VERSION != 'sb:user:en' ? "pt" : "en"} onClick={this.onLanguageClick}><span style={style.text}>{t('menu.txt_enter', 'ENTRAR')}</span></a>
        </nav>
      );
    }
  }

  renderNotPublished(txt) {
    return (
      <div className="not-published"><h3 className="txt-primary">{txt}</h3></div>
    )
  }

  onGetSubItems(id) {
    const { services } = this.context;

    services.itemListApi.getTabs(id)
      .then(({ ItemList }) => {
        this.setState({ tabdetail: { ItemList } });
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            Menu: {
              ...prevState.data.Menu,
              tablists: ItemList.tabs
            }
          }
        }));

        this.setState({ tab: true });
        window.scroll({ top: 0 })
      })
      .catch(err => {
      });
  }

  setClass(tab, detail) {
    let className = "";
    if (!tab && detail) {
      className = "areas opened"
    } else if (tab && !detail) {
      className = "areas tab"
    } else if (tab && detail) {
      className = "areas tab opened"
    } else {
      className = "areas";
    }
    return className;
  }

  render() {
    const { data, item, opened, detail, language, currency, tab, tabdetail } = this.state;
    const { t } = this.props;

    if (data && data.Menu) {
      const style = Styles.checkStyle(data.Menu);

      return (
        <div className="menu" style={style.background.backgroundColor || style.background.backgroundImage ? { backgroundColor: '#eee' } : null}>
          <div className={opened ? "app opened" : "app"} style={style.background}>
            <div className={this.setClass(tab, detail)}>
              <section>
                <header className="main-header">
                  <div className="cover">
                    <div className="image" style={style.cover}></div>
                    <div className="product" style={style.backgroundProduct}></div>
                  </div>
                  <div className="placeholder">
                    {this.checkLogo(data.Menu, style)}
                  </div>
                  {data.Menu.published && data.Menu.visible ? this.renderLanguages(data.Menu.show_english, style) : this.renderNotPublished(t('menu.txt_not_published', 'Este Menu Autêntico ainda não foi publicado.'))}
                  <div className="note" dangerouslySetInnerHTML={{ __html: t('menu.txt_note', 'Ao aceder ao Menu Autêntico, estás a concordar com os Termos de Uso. <br />Para saberes mais, lê os <a href="/pdf/termos-de-uso.pdf" target="_blank">Termos de Uso</a>.') }} />
                </header>
                <MenuList data={data.Menu} style={style} language={language} onSelected={this.onSelectedClick} onSubList={this.onGetSubItems} tab={false} />
              </section>
              <section className="tab-detail" style={tab ? { display: 'block' } : { display: 'none' }}>
                <header className="detail-header">
                  <a className="back" href="#" onClick={this.onBackMainMenu}>
                    <span className="icon-arrow-back" style={style ? style.customText : {}}></span>
                  </a>
                  <div className="back"></div>
                </header>
                <div className="list">
                  <div className="title sub" style={style ? style.buttons.background : {}}>
                    <img className="sep-icon" src={tabdetail && tabdetail.ItemList.icon ? tabdetail.ItemList.icon.file.publicUrl : ""} style={{ filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }} />
                    <h2 style={style.text}>{tabdetail && tabdetail.ItemList.name ? tabdetail.ItemList.name[language] : ""}</h2>
                  </div>
                </div>
                <MenuList data={data.Menu} tabdetail={tabdetail} style={style} language={language} onSelected={this.onSelectedClick} onSubList={this.onGetSubItems} tab={true} />
              </section>
              <section>
                <header className="detail-header">
                  <a className="back" href="#" onClick={this.onBackClick}>
                    <span className="icon-arrow-back" style={style ? style.customText : {}}></span>
                  </a>
                  <div className="back"></div>
                </header>
                <MenuItems data={item} style={style} language={language} currency={currency} show={data.Menu.show_shortcut} shortcut={data.Menu.shortcut} onSelected={this.onSelectedClick} t={t} />
                <figure className="banner">
                  <img src={t('menu.img_banner', "/images/banner-stout.png")} alt="" />
                </figure>
              </section>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

Menu.contextType = ServicesContext;

export default withTranslation()(Menu);
