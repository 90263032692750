import React, { Component } from 'react';
// import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import "./SortContent.scss";

// const SortableItem = sortableEl  ement(({ value }) => <div className="list-tab-item">{process.env.REACT_APP_VERSION != 'sb:user:en' ? value.name ? value.name.pt : '' : value.name ? value.name.en : ''}</div>);

// const SortableContainer = sortableContainer(({ children }) => {
//     return <div className="list-tabs">{children}</div>;
// });

const SortableItem = value => <div className="list-tab-item">{process.env.REACT_APP_VERSION != 'sb:user:en' ? value.name.pt : value.name.en}</div>;

const SortableContainer = children => {
    return <div className="list-tabs">{children}</div>;
};

class SortTabsContent extends Component {

    constructor(props) {
        super(props);

        const items = props.data.list;

        let filtered = [];

        for(var i = 0; i < items.length; i++) {
            if(!items[i].default) filtered.push(items[i]);
        }

        this.state = { 
            list: filtered
        };

        this.onSave = props.data.onSave;
        this.saveChanges = this.saveChanges.bind(this);
    }

    saveChanges = (event) => {
        event.preventDefault();
        
        const { services } = this.context;
        const { list } = this.state;

        let items = '';

        for(let i = 0 ; i < list.length; i++) {
            if(items.length) items += ',';
            items += `{ id: "${list[i].id}", data: { order: ${i} } }`;
        }

        services.itemListApi.updateMany(items)
            .then(({ updateItemLists }) => {
                if (updateItemLists) {
                    this.onSave();
                } else console.log("Error updating the Item");
            }).catch(error => console.log(error));
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ list }) => ({
            list: arrayMoveImmutable(list, oldIndex, newIndex),
        }));
    };

    render() {
        const { list } = this.state;
        const { t } = this.props;
        return (
            <div className="sort-tabs">
                <h2>{t('admin_sort_tabs.title', 'Ordenar separadores')}</h2>
                <SortableContainer onSortEnd={this.onSortEnd}>
                    {list.map((value, index) => (
                        <SortableItem key={value.id} index={index} value={value} />
                    ))}
                </SortableContainer>
                <div className="m-top-8">
                    <a onClick={this.saveChanges} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
            </div>
        )

    }
}

SortTabsContent.contextType = ServicesContext;

export default withTranslation()(SortTabsContent);
