import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { ServicesContext } from '../../context/services';

import theme from './theme';

class Menus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    };
  }

  componentDidMount() {
    const { services } = this.context;

    services.menusApi.getReport()
      .then(({ allMenus }) => {
        if (allMenus) {
          this.setState({ data: { allMenus } });
        }
      });
  }

  renderDate(isoDate) {
    if (isoDate) {
      var date = new Date(isoDate);
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    } else return "";
  }

  renderRows() {
    const lang = process.env.REACT_APP_VERSION;

    if (lang === 'sb:user') {
      return (
        <TableRow>
          <TableCell>Restaurante</TableCell>
          <TableCell>NIF</TableCell>
          <TableCell>Código Cliente</TableCell>
          <TableCell>Menu</TableCell>
          <TableCell>Publicado</TableCell>
          <TableCell>Encomendado</TableCell>
          <TableCell>Inglês</TableCell>
          <TableCell>Atalho</TableCell>
          <TableCell>Visitas</TableCell>
          <TableCell>Data</TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell>Restaurant</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>Menu</TableCell>
          <TableCell>Published</TableCell>
          <TableCell>Shortcut</TableCell>
          <TableCell>Page Views</TableCell>
          <TableCell>Created</TableCell>
          <TableCell>QR Code</TableCell>
        </TableRow>
      );
    }
  }

  renderData(data) {
    const lang = process.env.REACT_APP_VERSION;

    if (lang === 'sb:user') {
      return (
        <TableBody>
          {data.allMenus.map((menu) => (
            <TableRow key={menu.id}>
              <TableCell>{menu.restaurant}</TableCell>
              <TableCell>{menu.user ? menu.user.nif : ""}</TableCell>
              <TableCell>{menu.user ? menu.user.code : ""}</TableCell>
              <TableCell><a href={menu.url} target="_blank" rel="noopener noreferrer">{menu.name}</a></TableCell>
              <TableCell>{menu.published ? "Sim" : ""}</TableCell>
              <TableCell>{menu.order ? "Sim" : ""}</TableCell>
              <TableCell>{menu.show_english ? "Sim" : ""}</TableCell>
              <TableCell>{menu.show_shortcut ? "Sim" : ""}</TableCell>
              <TableCell>{menu.views ? menu.views : 0}</TableCell>
              <TableCell>{this.renderDate(menu.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          {data.allMenus.map((menu) => (
            <TableRow key={menu.id}>
              <TableCell>{menu.restaurant}</TableCell>
              <TableCell>{menu.user && menu.user.country ? menu.user.country.name : null}</TableCell>
              <TableCell><a href={menu.url} target="_blank" rel="noopener noreferrer">{menu.name}</a></TableCell>
              <TableCell>{menu.published ? "Yes" : ""}</TableCell>
              <TableCell>{menu.show_shortcut ? "Yes" : ""}</TableCell>
              <TableCell>{menu.views ? menu.views : 0}</TableCell>
              <TableCell>{this.renderDate(menu.createdAt)}</TableCell>
              <TableCell>
                {menu.qrcode ?
                  <a href={menu.qrcode} target="_blank" download={menu.id} rel="noopener noreferrer">
                    <img width="50" src={menu.qrcode} alt="QRCODE" />
                  </a>
                  : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }
  }

  render() {
    const { data } = this.state;


    if (data && data.allMenus) {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container>
            <Box my={4}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    {this.renderRows()}
                  </TableHead>
                  {this.renderData(data)}
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </ThemeProvider>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

Menus.contextType = ServicesContext;

export default Menus;