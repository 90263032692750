import React, { Component } from 'react';
import './AdminDetail.scss';
import { ServicesContext } from '../../context/services';
import { withTranslation } from 'react-i18next';

import Popup from './popups/Popup';
import Styles from "../utils/styles";

class AdminDetail extends Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      loading: false,
      currency: '€',
      items: null,
      popup: null,
      data: null
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.pickItem = this.pickItem.bind(this);
    this.sortItems = this.sortItems.bind(this);
    this.editTab = this.editTab.bind(this);
    this.tabChanged = this.tabChanged.bind(this);

    this.confirmDeleteTab = this.confirmDeleteTab.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
  }

  componentDidMount() {
    const id = this.id;
    const { services } = this.context;

    this.setState({ loading: true });

    services.itemListApi.getById(id)
      .then(({ ItemList }) => {
        const currency = this.getCurrency(ItemList);
        const items = this.sortByOrder(ItemList.items);
        this.setState({ data: { ItemList }, currency, items: items, loading: false });
      });
  }

  getCurrency(list) {
    let currency = this.state.currency;

    if (list.menu && list.menu.user) {
      if (list.menu.user.country) {
        currency = list.menu.user.country.currency;
      }
    }

    return currency;
  }

  sortByOrder(items) {
    items.sort(function (a, b) {
        if(a.order === null){
            let max = items.length;
            if(a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
            a.order = max;
        } 
        return a.order - b.order;
    });
    return items;
  }

  // loadItems() {
  //   const id = this.id;
  //   const { services } = this.context;

  //   this.setState({ loading: true });

  //   services.itemsApi.getByList(id)
  //     .then(({ allItems }) => {
  //       const items = this.sortByOrder(allItems);
  //       this.setState({ items: items, loading: false });
  //     });
  // }

  closePopup(e) {
    if(e){
      if(e.popup == "pick" && e.item.id){
        //open popup after product selected
        const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
        this.setState({ popup: { name: 'edit_item', id: e.item.id, show_english } });
      }else if(e.popup == "delete" && e.id){
        this.setState({ popup: { name: 'delete', id: e.id, onDelete: this.deleteItem } });
      }else if(e.popup == "addItem" && e.item.id){
        //this.setState({ popup: { name: 'add_sub_item_question', id: e.item.id, onAddSubItems: this.addSubItems } });
        this.setState({ popup: null, items: null });
        this.componentDidMount();
      }else if(e.popup == "change_tab" && e.id){
        this.setState({ popup: { name: 'change_tab', id: e.id, tab: e.tab, onChangedTab:this.tabChanged } });
      }else{
        e.preventDefault();
        this.setState({ popup: null, items: null });
        this.componentDidMount();
      }
    }else{
      this.setState({ popup: null, items: null });
      this.componentDidMount();
    }
  }

  tabChanged(id){
    console.log('tabChanged', id);
    this.id = id;
    this.setState({ popup: null, items: null });
    this.componentDidMount();
  }

  editTab(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    this.setState({
      popup: {
        name: "edit_tab",
        id: currentTarget.dataset.id,
        show_english,
        onDelete: this.confirmDeleteTab,
      },
    });
  }

  confirmDeleteTab(id) {
    this.setState({
      popup: { name: "delete", id: id, onDelete: this.deleteTab },
    });
  }

  deleteTab(id) {
    const { services } = this.context;

    services.itemListApi
      .delete(id)
      .then(({ deleteItemList }) => {
        if (deleteItemList && deleteItemList.id) {
          this.closePopup();
        } else console.log("Error deleting ItemList");
      })
      .catch((error) => console.log(error));
  }

  addItem(e) {
    //e.preventDefault();
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    this.setState({ popup: { name: 'add_item', list: this.id, show_english } });
  }

  pickItem(){
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    this.setState({ popup: { name: 'pick_item', list: this.id, show_english } });
  }

  selectItem(e){
    e.preventDefault();
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    this.setState({ popup: { name: 'select_item', list: this.id, show_english,  onAddItem: this.addItem, onPickItem: this.pickItem } });
  }

  editItem(e) {
    const { currentTarget } = e;
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    this.setState({ popup: { name: 'edit_item', id: currentTarget.dataset.id, show_english, parent: this.state.data} });
  }

  deleteItem(id) {
    const { services } = this.context;

    services.itemsApi.delete(id)
      .then(({ deleteItem }) => {
        if (deleteItem && deleteItem.id) {
          this.closePopup();
        } else console.log("Error deleting Item");
      }).catch(error => console.log(error));
  }

  confirmDelete(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const id = currentTarget.dataset.id;

    this.setState({ popup: { name: 'delete', id: id, onDelete: this.deleteItem } });
  }

  sortItems(e) {
    this.setState({ popup: { name: 'sort_detail', list: this.state.items, onSave: this.closePopup } });
  }

  showSortButton(label, style) {
    if (this.state.items) {

      if (this.state.items.length > 1) {
        return (
          <div className="bottom-btn" onClick={this.sortItems} style={style.buttons.border}>
            <span>{label}</span>
            <span className="icon-sort"></span>
          </div>
        )
      }
    }
  }

  hasThumbnail(data) {
    let show = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].image && data[i].image != 'null') show = true;
    }
    return show;
  }

  renderItems(items, currency, style) {
    if (items && items.length) {
      const showThumbnail = this.hasThumbnail(items);
      console.log(items)
      return (
        <ul>
          {items.map(item => (
            <li key={item.id} style={{opacity: item.visible === false ? 0.5 : 1 }}>
              <div className={showThumbnail ? 'item thumb' : 'item no-thumb'}>
                {showThumbnail ? <div className="thumb"><figure>{item.image && item.image != 'null' ? <img src={item.image}/> : ""}</figure></div> : ''}
                <div className={item.items.length > 0 ? "details full" : "details"}>
                  <div className="plate edit-btn">
                    <h3>{process.env.REACT_APP_VERSION != 'sb:user:en' ? item.name.pt : item.name.en}</h3>
                    <p>{process.env.REACT_APP_VERSION != 'sb:user:en' ? item.description.pt : item.description.en}</p>
                  </div>
                  {
                    item.items.length == 0 ?
                      <div className="price" style={style.color}>
                        <h4>{currency} {item.price}</h4>
                      </div> : null
                  }
                  {
                    item.items.length != 0 ?
                      <ul className="multi">
                        {item.items.map(sub => (
                          <li className="product-item" key={sub.id}>
                            <div className="product-label">{process.env.REACT_APP_VERSION != 'sb:user:en' ? sub.name.pt : sub.name.en}</div>
                            <div className="product-price" style={style.color}>{currency} {sub.price}</div>
                          </li>
                        ))}
                      </ul> : null
                  }
                </div>
              </div>
              <nav className="actions">
                <div data-id={item.id} onClick={this.editItem} >
                  <span className="icon-edit-bold" style={style ? style.color : ''}></span>
                </div>
                {/*
                <div data-id={item.id} onClick={this.confirmDelete} style={style.border}>
                  <img src="/images/admin/icons/delete.png" alt="Apagar" />
                </div>
                */}
              </nav>
            </li>
          ))}
        </ul>
      );
    }
  }

  checkDefault(item) {
    if (!item.default) {
      return (
        <div data-id={item.id} className="edit" onClick={this.editTab}><img src="/images/admin/icons/edit-white.png" alt="Editar" /></div>
      );
    }
  }

  backUrl(data){
    let url = "";
    if(data.ItemList.parent) url = "/admin/tab/" + data.ItemList.parent.id;
    else if(data.ItemList.subtab) url = "/admin/tab/" + data.ItemList.id;
    else url = "/admin/menu/" + data.ItemList.menu.id;
    return url;
  }

  render() {
    const { data, currency, popup, items, loading } = this.state;
    const { t } = this.props;

    if (data && data.ItemList) {
      const style = Styles.checkStyle(data.ItemList.menu);
      return (
        <div className="menu" style={ style.background.backgroundColor || style.background.backgroundImage ? {backgroundColor:'#eee'} : null }>
          <div className="app" style={style.background}>
          <header className="detail-header">
            <a className="back" href={this.backUrl(data)}>
              <span className="icon-arrow-back" style={style ? style.customText : ""}></span>
            </a>
            <div className="back"></div>
          </header>
          <div className="list">
            <div className="title" style={style ? style.buttons.background : {}}>
              <img className="sep-icon" src={data.ItemList.icon.file.publicUrl} alt={process.env.REACT_APP_VERSION != 'sb:user:en' ? data.ItemList.name ? data.ItemList.name.pt : '' : data.ItemList.name ? data.ItemList.name.en : ''} style={{filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }}/>
              <h2 style={style.text}>{process.env.REACT_APP_VERSION != 'sb:user:en' ? data.ItemList.name ? data.ItemList.name.pt : '' : data.ItemList.name ? data.ItemList.name.en : ''}</h2>
              {/* {this.checkDefault(data.ItemList)} */}
            </div>
            <div className="content">
              <div className={loading ? "list-wrap loading" : "list-wrap"}>
                {this.renderItems(items, currency, style.buttons)}
              </div>
              <div className="bottom-buttons m-top-4">
                <div className="bottom-btn" onClick={this.addItem}  style={style.buttons.border}>
                  <span>{t('btn.add_product', 'Adicionar Produto')}</span>
                  <span className="icon-add"></span>
                </div><div className="bottom-btn" onClick={this.pickItem}  style={style.buttons.border}>
                  <span>{t('btn.add_sb_product', 'Adicionar produto Super Bock')}</span>
                  <span className="icon-add"></span>
                </div>
                {/* {!loading ? this.showSortButton(t('btn.sort_products', 'Ordenar produtos'), style) : null} */}
              </div>
            </div>
            <footer></footer>
          </div>
          <figure className="banner">
            <img src="/images/banner-stout.png" alt="Beber para Crer" />
          </figure>
          <Popup data={popup} onClose={this.closePopup} style={style}/>
        </div>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

AdminDetail.contextType = ServicesContext;

export default withTranslation()(AdminDetail);