import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { ServicesContext } from '../../context/services';
import { QRCode } from 'react-qrcode-logo';

import theme from './theme';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      sent: {}
    };

    this.onCheckChanged = this.onCheckChanged.bind(this);
    this.getMenuURL = this.getMenuURL.bind(this);
    this.dwlQRCode = this.dwlQRCode.bind(this);
  }

  componentDidMount() {
    const { services } = this.context;

    services.ordersApi.getReport()
      .then(({ allOrders }) => {
        if (allOrders) {
          const orders = allOrders;
          if (orders) {
            let sent = {};
            for (var i = 0; i < orders.length; i++) {
              if (orders[i].sent) sent[orders[i].id] = orders[i].sent;
            }
            this.setState({ data: { allOrders }, sent });
          }

        }
      });
  }

  onCheckChanged(e) {
    const { target } = e;
    const { sent } = this.state;
    const { services } = this.context;

    services.ordersApi.update({ id: target.id, sent: target.checked })
      .then(result => {
        if (result.updateOrder) {
          sent[target.id] = !target.checked;
          this.setState({ sent: sent });
        }
      });
  }

  renderMaterials(order) {
    let text = '';

    if (order.product_1) {
      text += `${order.quantity_1} ${order.product_1.name}`;
    }

    if (order.product_2) {
      text += ` + ${order.quantity_2} ${order.product_2.name}`;
    }

    if (order.product_3) {
      text += ` + ${order.quantity_3} ${order.product_3.name}`;
    }

    return text;
  }

  renderDate(isoDate) {
    var date = new Date(isoDate);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  getMenuURL(id){
    const url = window.location.protocol+ "//" + window.location.hostname + (window.location.port ? ":"+window.location.port : "")  + "/menu/"+ id;
    return url;
  }

  dwlQRCode(e) {
    const { currentTarget } = e;
    const canvas = currentTarget.querySelector('canvas');
    
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = currentTarget.dataset.order + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  renderOrder(order, sent) {
    if (order.menu) {
      return (
        <TableRow key={order.id}>
          <TableCell component="th" scope="row"><b>{order.order}</b></TableCell>
          <TableCell>{order.menu ? order.menu.restaurant : ""}</TableCell>
          {/* <TableCell>{order.menu ? order.menu.user.name : ""}</TableCell> */}
          <TableCell>{this.renderMaterials(order)}</TableCell>
          <TableCell>{order.name}</TableCell>
          <TableCell>{order.address}</TableCell>
          <TableCell>{order.postal}</TableCell>
          <TableCell>{order.city}</TableCell>
          <TableCell>{order.contact}</TableCell>
          <TableCell>{this.renderDate(order.createdAt)}</TableCell>
          <TableCell>
            {order.menu ?
              <div onClick={this.dwlQRCode} id={order.menu.id} data-order={order.order}>
                <QRCode value={this.getMenuURL(order.menu.id)} fgColor="#000" size="300" logoWidth="82" logoImage="/images/logo-superbock-bw.png" />
              </div>
              : null}
          </TableCell>
          <TableCell>
            <Checkbox checked={sent[order.id] ? sent[order.id] : false} id={order.id} onChange={this.onCheckChanged}></Checkbox>
          </TableCell>
        </TableRow>
      );
    }
  }

  render() {
    const { data, sent } = this.state;

    if (data && data.allOrders) {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container>
            <Box my={4}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pedido</TableCell>
                      <TableCell>Restaurante</TableCell>
                      {/* <TableCell>Cliente</TableCell> */}
                      <TableCell>Materiais</TableCell>
                      <TableCell>Responsável</TableCell>
                      <TableCell>Morada</TableCell>
                      <TableCell>Código Postal</TableCell>
                      <TableCell>Localidade</TableCell>
                      <TableCell>Contacto</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>QR Code</TableCell>
                      <TableCell>Enviado?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.allOrders.map((order) => (
                      this.renderOrder(order, sent)
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </ThemeProvider>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

Orders.contextType = ServicesContext;

export default Orders;