import React, { Component } from 'react';

import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import "./ChangeTabContent.scss";
import { itemListApi } from '../../../services';

class ChangeTabContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            show_english: props.data.show_english,
            data: props.data,
            alltabs: null,
            maintab: null,
            subtab: null,
            currentMainId: null,
            currentSubId: null,
        };

        this.onComplete = props.onComplete;
        this.onChangedTab = props.data.onChangedTab;
        this.onChangeTab = this.onChangeTab.bind(this);
        this.onChangeSubTab = this.onChangeSubTab.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    sortByOrder(items) {
        items.sort(function (a, b) {
            if (a.order === null) {
                let max = items.length;
                if (a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
                a.order = max;
            }
            return a.order - b.order;
        });
        return items;
    }

    componentDidMount() {
        const { services } = this.context;
        console.log('data', this.state.data.tab.ItemList);
        if (this.state.data.tab.ItemList.parent) this.setState({ currentSubId: this.state.data.tab.ItemList.id });

        //get all tabs
        if (this.state.data.tab.ItemList.menu.id) {
            services.itemListApi.getByMenu(this.state.data.tab.ItemList.menu.id)
                .then(({ allItemLists }) => {
                    const items = this.sortByOrder(allItemLists);
                    this.setState({ alltabs: items });
                })
                .catch(err => {
                });
        }

        //get current item tab
        const id = this.state.data.tab && this.state.data.tab.ItemList.parent ? this.state.data.tab.ItemList.parent.id : this.state.data.tab.ItemList.id;
        this.getSelectedTabs(id);
    }

    getSelectedTabs(id) {
        const { services } = this.context;
        if (id) {
            services.itemListApi.getById(id)
                .then(({ ItemList }) => {
                    this.setState({ maintab: ItemList, currentMainId: id });
                    //get sub tabs
                    if (ItemList.subtab) {
                        services.itemListApi.getSubTabs(id)
                            .then(({ allItemLists }) => {
                                this.setState({ subtab: allItemLists });
                            });
                    } else {
                        this.setState({ subtab: null });
                    }
                })
                .catch(err => {
                });
        }
    }

    onChangeTab(e) {
        this.setState({
            currentMainId: e.currentTarget.value, currentSubId: null
        });
        this.getSelectedTabs(e.currentTarget.value);
    }
    onChangeSubTab(e) {
        this.setState({
            currentSubId: e.currentTarget.value
        });
    }
    saveChanges(e) {
        e.preventDefault();
        const tab = this.state.currentSubId ? this.state.currentSubId : this.state.currentMainId;
        const id = this.state.data.id;
        const { services } = this.context;

        const data = `{ lists: { connect: { id: "${tab}" } } }`;

        services.itemsApi.update(this.state.data.id, data)
            .then(({ updateItem }) => {
                console.log('updateItem', updateItem);
                if (updateItem && updateItem.id) {
                    //this.onComplete();
                    this.onChangedTab(tab);
                } else console.log("Error updating the Item");
            }).catch(error => console.log(error));
    }

    render() {
        const { loading, data, subtab, alltabs, currentMainId, currentSubId } = this.state;
        const { t } = this.props;
        console.log('currentMainId', currentMainId);
        console.log('currentSubId', currentSubId);
        return (
            <div className="change-tab">
                {alltabs ?
                    <div className="">
                        {
                            subtab && subtab.length > 0 ?
                                <div className="">
                                    <h2>{t('admin_change_tabs.txt_sub_tabs', 'Sub separadores')}</h2>
                                    <div className="tabs m-top-4">
                                        {
                                            subtab.map(tab => {
                                                let checked = tab.id === currentSubId ? true : false;
                                                return (
                                                    <div className="tab" key={tab.id}>
                                                        <input type="radio" id={tab.id} name="subtab" value={tab.id} checked={checked} onChange={this.onChangeSubTab} />
                                                        <label htmlFor={tab.id}>
                                                            <img className="icon" src={tab.icon.file.publicUrl} alt="" />
                                                            <span>{tab.name.pt}</span>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null
                        }
                        {
                            alltabs ?
                                <div className="m-top-8">
                                    <h2>{t('admin_change_tabs.txt_tabs', 'Separadores')}</h2>
                                    <div className="tabs m-top-4">
                                        {
                                            alltabs.map(tab => {
                                                let checked = tab.id === currentMainId ? true : false;
                                                return (
                                                    <div className="tab" key={tab.id}>
                                                        <input type="radio" id={tab.id} name="alltabs" value={tab.id} checked={checked} onChange={this.onChangeTab} />
                                                        <label htmlFor={tab.id}>
                                                            <img className="icon" src={tab.icon.file.publicUrl} alt="" />
                                                            <span>{tab.name.pt}</span>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null
                        }
                        <div className="m-top-8">
                            <a onClick={this.saveChanges} className="btn-primary">
                                {t('btn.save', 'Guardar')}
                            </a>
                        </div>
                    </div>
                    :
                    <div className="loader">
                        <img src="/images/loader.gif" alt="" />
                    </div>
                }
            </div>
        )
    }
}

ChangeTabContent.contextType = ServicesContext;

export default withTranslation()(ChangeTabContent);