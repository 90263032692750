import React, { Component } from 'react';

import './ForgotPassword.scss';
import { ServicesContext } from '../../context/services';

import ErrorPopup from './ErrorPopup';

import { withTranslation  } from 'react-i18next';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { email: "", errors: {}, success: false, genericError: "" };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    validate() {
        let errors = {};
        let { email } = this.state;
        const { t } = this.props;
        
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = pattern.test(email);
        errors.email = (isValidEmail ? null : t('error_msg.email', 'Email inválido!'));

        this.setState({ errors });

        return isValidEmail;
    }

    closePopup(e) {
        if (e) e.preventDefault();
        this.setState({ genericError: "" });
    }

    handleFocus(event) {
        const { name } = event.target;
        const { errors } = this.state;
        errors[name] = "";
        this.setState({ errors });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { t } = this.props;

        if (!this.validate()) return;

        const { email } = this.state;
        const { services } = this.context;

        services.usersApi.getByEmail(email)
            .then(({ allUsers }) => {
                if (allUsers && allUsers.length) {
                    const id = allUsers[0].id;
                    this.setState({ success: true });
                    this.sendEmail(id, this.state.email);
                } else this.setState({ genericError:  t('error_msg.email_not_sent', 'Não foi possível enviar e-mail') });
            }).catch(error => console.log(error));
    }

    sendEmail(id) {
        const { services } = this.context;

        services.authApi.sendForgotPasswordEmail({ id, email: this.state.email })
            .then(result => console.log(result));
    }

    render() {
        const { email, success, errors, genericError } = this.state;
        const { t } = this.props;

        if (!success) {
            return (
                <div className="menu">
                    <div className="app forms solo">
                    <header className="admin-header">
                        <figure className="logo">
                           <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
                        </figure>
                    </header>
                    <div className="content">
                        <div className="form">
                            <div className="m-bottom-6">
                                <h2>{t('admin_forgot_password.title', 'Recuperar password')}</h2>
                            </div>
                            <form>
                                <input
                                    type="text"
                                    name="email"
                                    value={email}
                                    placeholder={t('placeholder.email', 'E-mail')}
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                />
                                {errors.email ? <div className="error">{errors.email}</div> : null}
                            </form>
                            <div className="m-top-4">
                                <a onClick={this.handleSubmit} className="btn-primary">
                                    {t('btn.recover', 'Recuperar')}
                                </a>
                            </div>
                        </div>
                        <div className="m-top-4 m-bottom-10">
                            <a href="/login" className="btn-secundary">
                                {t('btn.login', 'Login')}
                            </a>
                        </div>
                    </div>
                    <ErrorPopup message={genericError} onClose={this.closePopup} />
                </div>
                </div>
            );

        } else {
            return (
                <div className="menu">
                    <div className="app forms">
                    <header className="admin-header">
                        <figure className="logo">
                           <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')}  alt={t('common.site_name', 'Menu Autêntico')} />
                        </figure>
                    </header>
                    <div className="content">
                        <div className="form">
                            <div className="m-bottom-6">
                                <h2>{t('admin_forgot_password.txt_success', "Foi enviado um email para alterar a sua password.")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            );
        }
    }
}

ForgotPassword.contextType = ServicesContext;

export default withTranslation()(ForgotPassword);