import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import './Signup.scss';

import { ServicesContext } from '../../context/services';
import ErrorPopup from './ErrorPopup';

import { withTranslation  } from 'react-i18next';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { password: "", password2: "", errors: {}, success: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    validate() {
        let errors = {};
        const { password, password2 } = this.state;
        const { t } = this.props;
        
        const isValidPassword = password.length < 8 ? null : true;
        errors.password = (isValidPassword ? null : t('error_msg.password_min', 'Password inválida (minímo de 8 caracteres)!'));

        const passwordsMatch = (password === password2);

        if (isValidPassword)
            errors.password2 = (passwordsMatch ? null : t('error_msg.password_equal', 'As passwords não são iguais.'));

        this.setState({ errors });

        return isValidPassword && passwordsMatch;

    }

    closePopup(e) {
        if (e) e.preventDefault();
        this.setState({ genericError: "" });
    }

    handleFocus(event) {
        const { name } = event.target;
        const { errors } = this.state;
        errors[name] = "";
        this.setState({ errors });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.validate()) return;

        const { services } = this.context;
        const id = this.props.match.params.id;
        const password = this.state.password;
        const { t } = this.props;

        services.authApi.changeUserPasswordById(id, password)
        .then(({ updateUser }) => {
            if(updateUser) {
                this.setState({ success: true });
            } else this.setState({ genericError:  t('error_msg.password_not_changed', 'Não foi possível alterar password') }); 
        });
    }

    render() {
        const { success, errors, genericError } = this.state;
        const { t } = this.props;

        if (!success) {
            return (
                <div className="menu">
                    <div className="app forms solo">
                    <header className="admin-header">
                        <figure className="logo">
                           <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
                        </figure>
                    </header>
                    <div className="content">
                        <div className="form">
                            <div className="title">
                                <h2>{t('admin_recover_password.title', 'Alterar password')}</h2>
                            </div>
                            <form>
                                <input
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    placeholder={t('placeholder.new_password', "Nova Password")}
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                />
                                {errors.password ? <div className="error">{errors.password}</div> : null}
                                <input
                                    type="password"
                                    name="password2"
                                    value={this.state.password2}
                                    placeholder={t('placeholder.confirm_password', "Confirmar password")}
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    className="m-top-4"
                                />
                                {errors.password2 ? <div className="error">{errors.password2}</div> : null}
                            </form>
                            <div className="m-top-4">
                                <div onClick={this.handleSubmit} className="btn-primary">
                                    {t('btn.recover', 'Recuperar')}
                                </div>
                            </div>
                        </div>
                        <div className="m-top-4 m-bottom-10">
                            <a href="/login" className="btn-secundary">
                                {t('btn.login', 'Login')}
                            </a>
                        </div>
                    </div>
                    <ErrorPopup message={genericError} onClose={this.closePopup} />
                </div>
                </div>
            );
        } else {
            return <Redirect to="/admin" />
        }
    }
}

ForgotPassword.contextType = ServicesContext;

export default withTranslation()(ForgotPassword);