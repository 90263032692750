export default function makeItemListApi({
    query,
    mutation,
}) {
    const parentInfo = `
        id,
        name {
            id,
            pt,
            en
        },
        icon {
            id,
            file {
                publicUrl
            }
        }
    `;

    const listFragment = `{
        id,
        order,
        default,
        name {
            pt,
            en
        },
        icon {
            name,
            file {
                publicUrl
            }
        },
        subtab,
        hide,
        parent {
            ${parentInfo}
        }
    }`;

    const itemListFragment = `{
        id,
        default,
        name {
            id,
            pt,
            en
        },
        icon {
            id,
            file {
                publicUrl
            }
        },
        menu {
            id,
            show_english,
            user {
                country {
                    currency
                }
            },
            bgTab, 
            bgColor,
            bgImage,
            btnColor,
            darkMode
        },	
        items {	
            id,	
            order,	
            name {	
                pt,	
                en	
            },	
            description {	
                pt,	
                en	
            },	
            items {	
                id,	
                name {	
                    pt,	
                    en	
                },	
                price	
            }	
            image,	
            price,
            visible
        },
        subtab,
        hide,
        parent {
            ${parentInfo}
        }
        tabs ${listFragment}
    }`;

    return {
        getById: id => query(`
			query {
				ItemList(where: { id: "${id}" }) ${itemListFragment}
			}
        `),
        getByMenu: mid => query(`
			query {
                allItemLists(where: { menu: { id: "${mid}" }, parent_is_null: true }) ${listFragment}
			}
        `),
        getTabs: id => query(`
            query {
                ItemList(where: { id: "${id}" }) ${itemListFragment}
            }
        `),
        getSubTabs: id => query(`
            query {
                allItemLists(where: { parent: { id: "${id}" } }, first: 1) ${listFragment}
            }
        `),
        add: itemList => mutation(`
            mutation {
                createItemList(data: {
                    name: {
                        create: {
                            pt: "${itemList.name.pt}",
                            en: "${itemList.name.en}"
                        }
                    },
                    icon: {
                        connect: { id: "${itemList.icon}" }
                    },
                    menu: {
                        connect: { id: "${itemList.menu}" }
                    }
                    subtab: ${itemList.sub_tab}
                    hide: ${itemList.hide_tab}
                }) { id }
            }
        `),
        addSub: itemList => mutation(`
            mutation {
                createItemList(data: {
                    name: {
                        create: {
                            pt: "${itemList.name.pt}",
                            en: "${itemList.name.en}"
                        }
                    },
                    icon: {
                        connect: { id: "${itemList.icon}" }
                    },
                    parent: {
                        connect: { id: "${itemList.parent}" }
                    },
                    menu: {
                        connect: { id: "${itemList.menu.id}" }
                    }
                    subtab: ${false}
                    hide: ${itemList.hide_tab}
                }) { id }
            }
        `),
        delete: id => mutation(`
            mutation {
                deleteItemList(id: "${id}") { id }
            }
        `),
        update: (id, data) => mutation(`
            mutation {
                updateItemList(id: "${id}", data: ${data} ) { id }
            }
        `),
        updateMany: (items) => mutation(`
            mutation {
                updateItemLists(data: [${items}] ) { id }
            }
        `),
    };
}